.App {
  width: 100%;
  height: 100vh;
}

.container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.video-js {
  width: 100%;
  height: 100vh;
}
